<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="timeSheets"
      item-key="meta.id"
      multi-sort
      sort-by="duration.start.firebaseFormat"
      :sort-desc="true"
      :items-per-page="15"
      :footer-props="footerProps"
      :search="search"
      class="elevation-2"
      max-height="800px auto"
      @click:row="routeDetails"
      no-data-text="Keine Stundenmeldungen vorhanden"
      no-results-text="Keine Stundenmeldungen gefunden"
    >
      <template v-slot:top>
        <v-card flat>
          <v-card-subtitle>
            <v-row>
              <v-col v-if="showAllTimeSheets" cols="12" sm="4" md="4">
                <v-text-field
                  v-model="search"
                  outlined
                  append-icon="mdi-magnify"
                  label="Nach Personen suchen"
                  :disabled="timeSheetsPersonFilter === 'own'"
                  hide-details="auto"
                  clearable
                  :dense="$vuetify.breakpoint.smAndDown"
                ></v-text-field>
              </v-col>
              <v-col
                v-if="showAllTimeSheets"
                :class="$vuetify.breakpoint.smAndUp ? 'ml-2' : ''"
              >
                <span class="mr-4">Filter nach Typ</span>
                <v-chip-group
                  v-model="timeSheetsPersonFilter"
                  mandatory
                  active-class="primary--text"
                >
                  <v-chip
                    label
                    value="all"
                    class="transparent-background font-weight-medium mr-2"
                    >Alle
                  </v-chip>
                  <v-chip
                    label
                    value="own"
                    class="transparent-background font-weight-medium"
                    >Eigene</v-chip
                  >
                </v-chip-group>
              </v-col>
              <v-col>
                <span class="mr-4">Filter nach Status</span>
                <v-chip-group
                  v-model="timeSheetsStateFilter"
                  mandatory
                  active-class="primary--text"
                >
                  <v-chip
                    label
                    value="all"
                    class="transparent-background font-weight-medium mr-2"
                    >Alle</v-chip
                  >
                  <v-chip
                    label
                    value="inProgress"
                    class="transparent-background font-weight-medium mr-2"
                    >In Bearbeitung</v-chip
                  >
                  <v-chip
                    label
                    value="toBeReviewed"
                    class="transparent-background font-weight-medium mr-2"
                    >Zu prüfen</v-chip
                  >
                </v-chip-group>
              </v-col>
              <!-- <v-col>
                            <span class="mr-4">Filter nach Person</span>
                            <v-autocomplete
                              :items="[]"
                              outlined
                              persistent-hint
                              return-object
                              label="Person auswählen"
                              item-text="displayName"
                              item-value="uid"
                              hide-details="auto"
                              dense
                            ></v-autocomplete>
                          </v-col> -->
            </v-row>
          </v-card-subtitle>
        </v-card>
        <v-divider></v-divider>
      </template>

      <template v-slot:group.header="{ items, isOpen, toggle }">
        <th :colspan="headers.length">
          <v-icon @click="toggle"
            >{{ isOpen ? "mdi-chevron-up" : "mdi-chevron-down" }}
          </v-icon>
          {{ stripPrefix(items[0].groupBy) }}
        </th>
      </template>
      <template v-slot:[`item.user.displayName`]="{ item }">
        <div class="d-flex flex-column">
          <span
            class="d-block font-weight-semibold text--primary text-truncate"
            >{{ item.user.displayName }}</span
          >
          <!-- <small class="grey--text">{{ item.group.title }}</small> -->
          <small class="grey--text">Benutzergruppe</small>
        </div>
      </template>
      <template #[`item.full_name`]="{ item }">
        <div class="d-flex flex-column">
          <span
            class="d-block font-weight-semibold text--primary text-truncate"
            >{{ item.full_name }}</span
          >
          <small>{{ item.post }}</small>
        </div>
      </template>
      <!-- status -->
      <template #[`item.status`]="{ item }">
        <time-sheet-status-chip :status="item.status" small>
        </time-sheet-status-chip>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { auth } from "@/firebase";
import TimeSheetStatusChip from "@/components/accounting/time-sheets/TimeSheetStatusChip.vue";
import { ACCOUNTING_TIME_SHEETS_read_all } from "@/data/permission-types.js";
export default {
  name: "time-sheets-data-table",
  props: {
    timeSheets: {
      type: Array,
      required: true,
    },
  },
  components: {
    TimeSheetStatusChip,
  },
  data() {
    return {
      search: "",
      timeSheetsPersonFilter: "own",
      timeSheetsStateFilter: "active",
      currentUserUid: auth.currentUser.uid,
      footerProps: {
        "items-per-page-options": [15, 25, 50, 100, -1],
      },
    };
  },
  computed: {
    showAllTimeSheets() {
      return this.$store.getters["organization/checkPermissionByID"](
        `${ACCOUNTING_TIME_SHEETS_read_all}`
      );
    },
    headers() {
      const headersArray = [];

      if (this.timeSheetsPersonFilter === "all") {
        headersArray.push({
          text: "Name",
          value: "user.displayName",
          filter: (value) => {
            if (!this.reportAssignedToFilter) return true;
            if (this.timeSheetsPersonFilter === "all") return true;
            if (this.timeSheetsPersonFilter === "own") {
              // return value if it matches with variable "uid"
              return value === this.currentUserUid;
            }
          },
        });
      }

      headersArray.push(
        { text: "Tätigkeit", value: "title" },
        {
          text: "Status",
          value: "status",
          filter: (value) => {
            if (!this.timeSheetsStateFilter) return true;
            if (this.timeSheetsStateFilter === "all") return true;
            return value === this.timeSheetsStateFilter;
          },
        },
        { text: "Startzeitpunkt", value: "duration.start.timestamp" },
        { text: "Endzeitpunkt", value: "duration.end.timestamp" },
        { text: "Fortschritt", value: "progress" }
      );

      return headersArray;
    },
  },
  methods: {
    routeDetails(item) {
      this.$router.push({
        name: "accounting-payroll-details",
        params: {
          organizationId: this.$route.params.organizationId,
          itemId: item.meta.id,
        },
      });
    },
  },
};
</script>
