<template>
  <div>
    <NavigationBar
      titel="Stundenmeldungen"
      isPreview
      :actions="actions"
    ></NavigationBar>
    <section>
      <template>
        <v-container>
          <v-row>
            <v-col cols="12">
              <Subheader icon="mdi-invoice-clock" title="Stundenmeldungen">
              </Subheader>
              <div class="mt-7">
                <time-sheets-data-table
                  :timeSheets="timeSheets"
                ></time-sheets-data-table></div
            ></v-col>
          </v-row>
        </v-container>
      </template>
    </section>
    <section v-if="checkIfSupport">
      <support-tools :sources="code"></support-tools>
    </section>
  </div>
</template>

<script>
import NavigationBar from "@/components/_systemwide/NavigationBar.vue";
import Subheader from "@/components/_systemwide/Subheader.vue";
import SupportTools from "@/components/_system/helpers/SupportTools.vue";
import TimeSheetsDataTable from "@/components/accounting/time-sheets/TimeSheetsDataTable.vue";
import { ACCOUNTING } from "@/store/modules.js";
import { GET_TIME_SHEETS } from "@/store/action-types.js";
import { ACCOUNTING_TIME_SHEETS_create } from "@/data/permission-types.js";
export default {
  name: "accounting-time-sheets",
  components: {
    NavigationBar,
    Subheader,
    TimeSheetsDataTable,
    SupportTools,
  },
  data() {
    return {
      actions: [
        {
          title: "Erstellen",
          permission: `${ACCOUNTING_TIME_SHEETS_create}`,
          icon: "mdi-plus-circle",
          actionStyle: "textBtn",
          function: () => {
            this.$router.push({ name: "accounting-time-sheets-new" });
          },
        },
      ],
    };
  },
  computed: {
    timeSheets() {
      return this.$store.state.accounting.timeSheets;
    },
    checkIfSupport() {
      return this.$store.getters["organization/checkIfSupport"];
    },
    code() {
      return [{ title: "timeSheets", data: this.timeSheets }];
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      this.$store.dispatch(`${ACCOUNTING}${GET_TIME_SHEETS}`, {
        organizationId: this.$route.params.organizationId,
      });
    },
  },
};
</script>
